<template>
  <div>
    <nav class="navbar navbar-dark bg-dark justify-content-between flex-nowrap flex-row">
      <div class="container-fluid px-5">
        <router-link class="navbar-brand nav-link mx-4" to="/">API WP</router-link>
        <ul class="nav navbar-nav flex-row float-right">
          <li class="nav-item">
            <router-link class="nav-link mx-4" to="/pages">Pages</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/articles">Articles</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container mt-5">
      <router-view></router-view>
    </div>
  </div>
</template>