<template>
  <div class="container-fluid">
    <div class="row">
      <h2 class="display-12 text-center">
        Bienvenue sur le test REST API de WP
      </h2>
      <div class="mw-50 border p-4 mt-4">
        <div class="d-flex justify-content-center">
          <div class="form-outline">
            <input
              type="url"
              id="base-url"
              v-model="baseUrl"
              placeholder="URL WP"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <p class="text-center mt-3">
        Pour voir le résultat : <br />
        <router-link class="d-inline" to="/pages">Pages</router-link> ou
        <router-link class="d-inline" to="/articles">Articles</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: "",
    };
  },
  mounted() {
    if (localStorage.baseUrl) {
      this.baseUrl = localStorage.baseUrl;
    }
  },
  watch: {
    baseUrl(newbaseUrl) {
      localStorage.baseUrl = newbaseUrl;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background-color: var(--bs-body-color) !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
