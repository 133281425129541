import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/Home')
  },
  {
    path: '/pages',
    name: 'pages',
    component: () => import('../components/Pages')
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('../components/Articles')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
