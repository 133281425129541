import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'

// import 'bootstrap/dist/css/bootstrap.css'
// import '../node_modules/bootstrap/scss/bootstrap.scss';
import './assets/css/custom.scss';
import 'bootstrap/dist/js/bootstrap.min.js'

createApp(App).use(VueAxios, axios).use(router).mount('#app')

